html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1;
}

footer {
  background-color: #343a40;
  color: white;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  margin-top: auto; /* Asegura que el footer esté en la parte inferior */
}

.bg-custom{
  background-color: #F2F4F8!important;
}


.scrollable-x-row {
  max-width: 100%;
  overflow-x: auto;
}


.scrollable-row {
  max-height: 400px;
  overflow-y: auto;
}
.scrollable-row-500{
  max-height: 500px;
  overflow-y: auto;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('../public/fonts/Barlow-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


body {
  font-family: 'Barlow', sans-serif !important;
  font-weight: 800 !important; /* Ajusta el grosor aquí, prueba con 500, 600, 700, etc. */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.title {
  font-family: 'Barlow', sans-serif !important;
  font-weight: 900 !important; /* Mantén 700 para los títulos, o prueba con 800 si necesitas más grosor */
  font-size: 2em !important;
}


.scrollable {
  overflow-y: auto;
  max-height: 80vh; /* Ajusta la altura según tus necesidades */
}

.table-scrollable {
  max-height: 60vh; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto; /* Activa el scroll vertical */
  border: 1px solid #dee2e6; /* Opcional: Añade un borde al contenedor */
}