.login-container {
    max-width: 400px;
    width: 100%;
    padding: 20px;
    margin: auto;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error-message {
    color: red;
    margin-top: 5px;
    font-size: 0.875em;
}

/* Ajuste de inputs para dispositivos móviles */
@media (max-width: 768px) {
    .login-container {
        padding: 15px;
    }

    .form-control {
        font-size: 1em;
    }

    .input-group-text {
        font-size: 1em;
    }
}